import React from "react";
import {WebRTCState, WebRTCStatistics} from "@digitalstage/api-types";

export type TrackMap = { [streamId: string]: MediaStream }
export type AudioNodeMap = { [streamId: string]: AudioNode }
export type StatisticsMap = {
    [stageDeviceId: string]: WebRTCState & WebRTCStatistics & {
        warnings: string[],
        errors: Error[]
    }
}
export type DispatchWebRTC = {
    setRemoteVideoTracks: React.Dispatch<React.SetStateAction<TrackMap>>
    setRemoteAudioTracks: React.Dispatch<React.SetStateAction<TrackMap>>
    setRemoteAudioNodes:  React.Dispatch<React.SetStateAction<AudioNodeMap>>
    setStatistics: React.Dispatch<React.SetStateAction<StatisticsMap>>
}

const RemoteVideoTracksContext = React.createContext<TrackMap | null>(null)
const RemoteAudioTracksContext = React.createContext<TrackMap | null>(null)
const RemoteAudioNodesContext = React.createContext<AudioNodeMap | null>(null)
const StatisticsContext = React.createContext<StatisticsMap | null>(null)

const DispatchWebRTCContext = React.createContext<DispatchWebRTC | null>(null)

const WebRTCProvider = ({children}: { children: React.ReactNode }): JSX.Element => {
    const [remoteVideoTracks, setRemoteVideoTracks] = React.useState<TrackMap>({})
    const [remoteAudioTracks, setRemoteAudioTracks] = React.useState<TrackMap>({})
    const [remoteStreams, setRemoteAudioNodes] = React.useState<AudioNodeMap>({})
    const [statistics, setStatistics] = React.useState<StatisticsMap>({})

    return (
        <DispatchWebRTCContext.Provider value={{
            setRemoteVideoTracks,
            setRemoteAudioTracks,
            setRemoteAudioNodes,
            setStatistics
        }}>
                        <RemoteVideoTracksContext.Provider value={remoteVideoTracks}>
                            <RemoteAudioTracksContext.Provider value={remoteAudioTracks}>
                                <RemoteAudioNodesContext.Provider
                                    value={remoteStreams}
                                >
                                    <StatisticsContext.Provider value={statistics}>
                                        {children}
                                    </StatisticsContext.Provider>
                                </RemoteAudioNodesContext.Provider>
                            </RemoteAudioTracksContext.Provider>
                        </RemoteVideoTracksContext.Provider>
        </DispatchWebRTCContext.Provider>
    )
}

const useWebRTCDispatcher = (): DispatchWebRTC => {
    const state = React.useContext(DispatchWebRTCContext)
    if (state === null)
        throw new Error('useWebRTCDispatcher must be used within a WebRTCProvider')
    return state
}

const useWebRTCRemoteVideos = (): TrackMap => {
    const state = React.useContext(RemoteVideoTracksContext)
    if (state === null)
        throw new Error('useWebRTCRemoteVideoTracks must be used within a WebRTCProvider')
    return state
}
const useWebRTCRemoteAudioTracks = (): TrackMap => {
    const state = React.useContext(RemoteAudioTracksContext)
    if (state === null)
        throw new Error('useWebRTCRemoteAudioTracks must be used within a WebRTCProvider')
    return state
}
const useWebRTCRemoteAudioNodes = (): AudioNodeMap => {
    const state = React.useContext(RemoteAudioNodesContext)
    if (state === null)
        throw new Error('useWebRTCRemoteAudioNodes must be used within a WebRTCProvider')
    return state
}
const useWebRTCStatistics = (): StatisticsMap => {
    const state = React.useContext(StatisticsContext)
    if (state === null)
        throw new Error('useWebRTCStatistics must be used within a WebRTCProvider')
    return state
}

export {
    WebRTCProvider,
    useWebRTCRemoteVideos,
    useWebRTCRemoteAudioTracks,
    useWebRTCRemoteAudioNodes,
    useWebRTCStatistics,
    useWebRTCDispatcher
}
