import {useEmit, useTrackedSelector} from "@digitalstage/api-client";
import React, {ChangeEvent} from "react";
import {ClientDeviceEvents} from "@digitalstage/api-types";
import {FormattedMessage} from "react-intl";
import {Switch} from "@digitalstage/ui/Switch";

const MonitorModeSelector = ({deviceId}: { deviceId: string }) => {
    const store = useTrackedSelector()
    const emit = useEmit()

    const onChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (emit) {
            emit(ClientDeviceEvents.ChangeDevice, {
                _id: deviceId,
                monitor: event.currentTarget.checked
            })
        }
    }, [deviceId, emit])

    return (
        <label style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '8px'
        }}>
            <span className="micro muted" style={{
                paddingBottom: '4px',
            }}>
                <FormattedMessage defaultMessage="Monitor" id="YpVoLg"/>
            </span>
            <Switch
                round
                small
                checked={store.devices.byId[deviceId]?.monitor || false}
                onChange={onChange}
            />
        </label>
    )
}

export {MonitorModeSelector}
