import React from "react";

type TrackMap = { [trackId: string]: MediaStream }

export type DispatchMediasoup = {
    setRemoteVideoTracks: React.Dispatch<React.SetStateAction<TrackMap>>,
    setRemoteAudioTracks: React.Dispatch<React.SetStateAction<TrackMap>>,
}

const RemoteVideoTracksContext = React.createContext<TrackMap | null>(null)
const RemoteAudioTracksContext = React.createContext<TrackMap | null>(null)

const DispatchMediasoupContext = React.createContext<DispatchMediasoup | null>(null)

const MediasoupProvider = ({children}: { children: React.ReactNode }): JSX.Element => {
    const [remoteVideoTracks, setRemoteVideoTracks] = React.useState<TrackMap>({})
    const [remoteAudioTracks, setRemoteAudioTracks] = React.useState<TrackMap>({})

    return (
        <DispatchMediasoupContext.Provider value={{
            setRemoteVideoTracks,
            setRemoteAudioTracks
        }}>
                <RemoteVideoTracksContext.Provider value={remoteVideoTracks}>
                    <RemoteAudioTracksContext.Provider value={remoteAudioTracks}>
                                {children}
                    </RemoteAudioTracksContext.Provider>
                </RemoteVideoTracksContext.Provider>
        </DispatchMediasoupContext.Provider>
    )
}
const useMediasoupDispatcher = (): DispatchMediasoup => {
    const state = React.useContext(DispatchMediasoupContext)
    if (state === null)
        throw new Error('useMediasoupDispatcher must be used within a MediasoupProvider')
    return state
}
const useMediasoupRemoteVideos = (): TrackMap => {
    const state = React.useContext(RemoteVideoTracksContext)
    if (state === null)
        throw new Error('useMediasoupRemoteVideos must be used within a MediasoupProvider')
    return state
}
const useMediasoupRemoteAudioTracks = (): TrackMap => {
    const state = React.useContext(RemoteAudioTracksContext)
    if (state === null)
        throw new Error('useMediasoupRemoteAudioTracks must be used within a MediasoupProvider')
    return state
}

export {MediasoupProvider, useMediasoupRemoteVideos, useMediasoupRemoteAudioTracks, useMediasoupDispatcher}
