import {useEmit, useTrackedSelector} from "@digitalstage/api-client";
import React, {ChangeEvent} from "react";
import {ClientDeviceEvents, ClientDevicePayloads} from "@digitalstage/api-types";
import debounce from "lodash/debounce";
import {FormattedMessage} from "react-intl";

function MonitorController({deviceId}: { deviceId: string }) {
    const store = useTrackedSelector()
    const {balance} = store.devices.byId[deviceId];
    const [value, setValue] = React.useState<number>(balance * 100)
    const emit = useEmit()

    const onChange = React.useCallback((v: number) => {
        if (emit) {
            emit(ClientDeviceEvents.ChangeDevice, {
                _id: deviceId,
                balance: v,
            } as ClientDevicePayloads.ChangeDevice)
        }
    }, [deviceId, emit])

    const onChangeDebounced = React.useMemo(() => debounce(onChange, 200), [onChange])

    const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const changedValue = Number.parseInt(event.currentTarget.value)
        setValue(changedValue)
        onChangeDebounced(changedValue / 100)
    }, [onChangeDebounced])

    React.useEffect(() => {
        setValue(balance * 100)
    }, [balance])

    return (
        <>
            <span className="muted"><FormattedMessage defaultMessage="Me" id="TdbPNK"/></span>
            <input type="range" min={0} max={100} step={10} value={value} onChange={handleChange}/>
            <span className="muted"><FormattedMessage defaultMessage="Others" id="fqJtkV"/></span>
        </>
    )
}


export {MonitorController}
