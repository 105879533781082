import {RootState, useEmit, useTrackedSelector} from "@digitalstage/api-client";
import {ImExit} from "react-icons/im";
import {ClientDeviceEvents} from "@digitalstage/api-types";
import React from "react";
import {useIntl} from "react-intl";
import {MdOutlineScreenShare, MdOutlineStopScreenShare, MdVideocam, MdVideocamOff} from "react-icons/md";
import {JammerMicrophoneController} from "./JammerMicrophoneController";
import {WebMicrophoneController} from "./WebMicrophoneController";
import {MonitorController} from "./MonitorController";
import {MonitorModeSelector} from "./MonitorModeSelector";
import styles from "./DeviceController.module.css"
import {GrVolumeControl} from "react-icons/gr";

export const getStageVideoType = (state: RootState): string => {
    return state.globals.stageId && state.stages.byId[state.globals.stageId]?.videoType || "browser"
}

export const getStageAudioType = (state: RootState): string => {
    return state.globals.stageId && state.stages.byId[state.globals.stageId]?.audioType || "browser"
}

export const getVideoDeviceIds = (state: RootState): string[] => {
    const videoType = getStageVideoType(state)
    if (videoType === "browser") {
        return state.devices.allIds.filter(id => state.devices.byId[id]?.online && state.devices.byId[id]?.canVideo && state.devices.byId[id]?.type === videoType)
    }
    return []
}

export const getAudioDeviceIds = (state: RootState): string[] => {
    const audioType = getStageAudioType(state)
    /*if (audioType === "browser") {
    }*/
    return state.devices.allIds.filter(id => state.devices.byId[id]?.online && state.devices.byId[id]?.canAudio && state.devices.byId[id]?.type === audioType)
}

export const getScreenDeviceIds = (state: RootState): string[] => {
    const videoType = getStageVideoType(state)
    if (videoType === "browser") {
        return state.devices.allIds.filter(id => state.devices.byId[id]?.online && state.devices.byId[id]?.canScreen && state.devices.byId[id]?.type === videoType)
    }
    return []
}

export const onJammerStage = (state: RootState) => {
    return getStageAudioType(state) === "jammer"
}


const DeviceController = (): JSX.Element => {
    const state = useTrackedSelector()
    const intl = useIntl()
    const emit = useEmit()
    const stageName = state.globals.stageId && state.stages.byId[state.globals.stageId]?.name || undefined

    const videoDeviceIds = getVideoDeviceIds(state)
    const audioDeviceIds = getAudioDeviceIds(state)
    const screenDeviceIds = getScreenDeviceIds(state)

    const localSendVideo = state.globals.localDeviceId && state.devices.byId[state.globals.localDeviceId]?.sendVideo || false
    const localSendScreen = state.globals.localDeviceId && state.devices.byId[state.globals.localDeviceId]?.sendScreen || false

    const isJammerStage = onJammerStage(state)

    const toggleLocalWebcam = React.useCallback(() => {
        if (emit && state.globals.localDeviceId) {
            emit(ClientDeviceEvents.ChangeDevice, {
                _id: state.globals.localDeviceId,
                sendVideo: !localSendVideo,
            })
        }
    }, [emit, state.globals.localDeviceId, localSendVideo])

    const toggleLocalScreenShare = React.useCallback(() => {
        if (emit && state.globals.localDeviceId) {
            emit(ClientDeviceEvents.ChangeDevice, {
                _id: state.globals.localDeviceId,
                sendScreen: !localSendScreen,
            })
        }
    }, [emit, state.globals.localDeviceId, localSendScreen])

    const leave = React.useCallback(() => {
        if (emit) {
            emit(ClientDeviceEvents.LeaveStage)
        }
    }, [emit])

    const [monitorVisible, setMonitorVisible] = React.useState<boolean>(false)

    const toggleMonitor = React.useCallback(() => setMonitorVisible(prev => !prev), [])

    return (
        <div className={styles.wrapper}>
            {/*TODO: AudioDeviceIds are only web-based, but should also contain jammer?!? But mute is currently not usable */}
            {(monitorVisible && state.globals.localDeviceId && state.globals.stageId && audioDeviceIds.length > 0) && (
                <div className={styles.monitorWrapper}>
                    {isJammerStage
                        ? (
                            <MonitorController deviceId={audioDeviceIds[0]}/>
                        )
                        : (
                            <>
                                <MonitorModeSelector deviceId={state.globals.localDeviceId}/>
                                <MonitorController deviceId={state.globals.localDeviceId}/>
                            </>
                        )}
                </div>
            )}
            <div className={styles.controlWrapper}>
                {(state.globals.stageId && audioDeviceIds.length > 0) && (
                    <button className="round large" onClick={toggleMonitor}>
                        <GrVolumeControl/>
                    </button>
                )}
                {audioDeviceIds.length > 0 && (
                    <>
                        {isJammerStage
                            ? <JammerMicrophoneController/>
                            : state.globals.localDeviceId &&
                            <WebMicrophoneController deviceId={state.globals.localDeviceId}/>
                        }
                    </>
                )}
                {videoDeviceIds.length > 0 && (
                    <button
                        // When this can video -> toggleLocalVideo
                        // When this cannot video -> toggle all others
                        className={`round large ${localSendVideo ? 'success' : 'danger'}`}
                        onClick={toggleLocalWebcam}
                        aria-label={localSendVideo
                            ? intl.formatMessage({defaultMessage: 'Stop sharing video', id: '5NJR7A'})
                            : intl.formatMessage({defaultMessage: 'Share video', id: 'mzFP3G'})}
                        title={localSendVideo
                            ? intl.formatMessage({defaultMessage: 'Stop sharing video', id: '5NJR7A'})
                            : intl.formatMessage({defaultMessage: 'Share video', id: 'mzFP3G'})}
                    >
                        {localSendVideo ? <MdVideocam/> : <MdVideocamOff/>}
                    </button>
                )}
                {screenDeviceIds.length > 0 && (
                    <button
                        className={`large round ${localSendScreen ? 'success' : 'danger'}`}
                        onClick={toggleLocalScreenShare}
                        aria-label={localSendScreen
                            ? intl.formatMessage({defaultMessage: 'Stop sharing screen', id: '/0jHY/'})
                            : intl.formatMessage({defaultMessage: 'Share screen', id: 'bLxNY9'})}
                        title={localSendScreen
                            ? intl.formatMessage({defaultMessage: 'Stop sharing screen', id: '/0jHY/'})
                            : intl.formatMessage({defaultMessage: 'Share screen', id: 'bLxNY9'})}
                    >
                        {(localSendScreen) ? <MdOutlineScreenShare/> : <MdOutlineStopScreenShare/>}
                    </button>
                )}
                {stageName ? (
                    <button
                        className="round secondary large"
                        disabled={!emit}
                        onClick={leave}
                        aria-label={intl.formatMessage({
                            defaultMessage: 'Leave stage {name}',
                            id: 'CTD0tm'
                        }, {name: stageName})}
                        title={intl.formatMessage({
                            defaultMessage: 'Leave stage {name}',
                            id: 'CTD0tm'
                        }, {name: stageName})}
                    >
                        <ImExit/>
                    </button>
                ) : null}
            </div>
        </div>
    )
}
export {DeviceController}