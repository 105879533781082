import React from "react";

const TOGGLE_WHEN_MOUSE_FREEZES_ON_WHOLE_SCREEN = false
export const TOGGLE_TIMEOUT = 4000

const Context = React.createContext<{
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

const VisibilityProvider = ({children}: { children: React.ReactNode }) => {
    const [visible, setVisible] = React.useState<boolean>(true)

    React.useEffect(() => {
            if (visible) {
                const listener = () => setVisible(false)
                document.body.addEventListener("mouseleave", listener)
                return () => {
                    document.body.removeEventListener("mouseleave", listener)
                }
            } else {
                const listener = () => setVisible(true)
                document.body.addEventListener("mouseenter", listener)
                return () => {
                    document.body.removeEventListener("mouseenter", listener)
                }

            }
    }, [visible])

    React.useEffect(() => {
        if (TOGGLE_WHEN_MOUSE_FREEZES_ON_WHOLE_SCREEN) {
            if (visible) {
                // Hide, if mouse does not move longer than 3 seconds
                let timeout: number | undefined = undefined
                const timedListener = () => {
                    if (timeout) {
                        window.clearTimeout(timeout)
                    }
                    timeout = window.setTimeout(() => {
                        setVisible(false)
                    }, TOGGLE_TIMEOUT)
                }
                document.body.addEventListener("mousemove", timedListener)
                return () => {
                    if (timeout)
                        window.clearTimeout(timeout)
                    document.body.removeEventListener("mousemove", timedListener)
                }
            } else {
                const listener = () => setVisible(true)
                document.body.addEventListener("mousemove", listener)
                return () => {
                    document.body.removeEventListener("mousemove", listener)
                }
            }
        }
    }, [visible])

    return (
        <Context.Provider value={{
            visible,
            setVisible
        }}>
            {children}
        </Context.Provider>
    )
}

const useVisibility = (): {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
} => {
    const state = React.useContext(Context)
    if (state === null) {
        throw new Error('useVisibility must be used within a VisibilityProvider')
    }
    return state
}

export {VisibilityProvider, useVisibility}