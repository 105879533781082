import styles from "./SideBar.module.css"
import React from "react";
import Link from "next/link";
import {useRouter} from "next/router";
import Image from "next/image";
import {Backdrop} from "../Backdrop";

const SideBarItem = ({danger, href, children, external}: {
    danger?: boolean,
    href: string,
    children: React.ReactNode,
    external?: boolean
}) => {
    const {pathname} = useRouter()
    return (
        <Link href={href}>
            <a className={`${styles.item} ${(!external && pathname === href) ? styles.active : ''} ${danger ? styles.danger : ''}`} target={external ? "_blank" : undefined}>
                {children}
            </a>
        </Link>
    )
}

const SideBar = React.memo(({
                                className,
                                visible,
                                children,
                                footer
                            }: { className?: string, visible?: boolean, children: React.ReactNode, footer: React.ReactNode }) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const handleClick = React.useCallback(() => setOpen(prev => !prev), [])
    const {pathname} = useRouter()

    React.useEffect(() => {
        if(pathname) {
            // Close burger menu if any navigation happens
            setOpen(false)
        }
    }, [pathname])

    return (
        <>
            <button className={styles.burgerButton} onClick={handleClick}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em"
                     width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                </svg>
            </button>
            <Backdrop className={styles.backdrop} open={open} onClick={handleClick}/>
            <div className={`${styles.bar} ${visible ? styles.visible : ''} ${className || ''}`}>
                <div className={`${styles.menu} ${open ? styles.open : ''}`}>
                    <div className={styles.logo}>
                        <Image src="/logo.svg" width={38} height={38} alt="Digital Stage"/>
                    </div>
                    <div className={styles.spacer}/>
                    <nav className={styles.nav}>
                        {children}
                    </nav>
                    <div className={styles.spacer}/>
                    <nav className={styles.nav}>
                        {footer}
                    </nav>
                </div>
            </div>
        </>
    )
})
SideBar.displayName = "SideBar"
export {SideBar, SideBarItem}