import {combineReducers} from "redux";
import {reduceAuth} from "./reduceAuth";
import {reduceGlobals} from "./reduceGlobals";
import {reduceChatMessage} from "./reduceChatMessage";
import {reduceDevices} from "./reduceDevices";
import {reduceSoundCards} from "./reduceSoundCards";
import {reduceUsers} from "./reduceUsers";
import {reduceStages} from "./reduceStages";
import {reduceGroups} from "./reduceGroups";
import {reduceStageMembers} from "./reduceStageMembers";
import {reduceStageDevices} from "./reduceStageDevices";
import {reduceVideoTracks} from "./reduceVideoTracks";
import {reduceAudioTracks} from "./reduceAudioTracks";
import {reduceNotifications} from "./reduceNotifications";
import {reduceCustomGroups} from "./reduceCustomGroups";

const rootReducer = combineReducers({
    auth: reduceAuth,
    globals: reduceGlobals,
    chatMessages: reduceChatMessage,
    devices: reduceDevices,
    soundCards: reduceSoundCards,
    users: reduceUsers,
    stages: reduceStages,
    groups: reduceGroups,
    customGroups: reduceCustomGroups,
    stageMembers: reduceStageMembers,
    stageDevices: reduceStageDevices,
    videoTracks: reduceVideoTracks,
    audioTracks: reduceAudioTracks,
    notifications: reduceNotifications,
})
export {rootReducer}
