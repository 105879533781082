import React from "react";
import {useRouter} from "next/router";

const useEulaVersion = () => {
    const {locale} = useRouter()
    const [version, setVersion] = React.useState<number>()

    // Get eula version
    React.useEffect(() => {
        import ("../../../content/eula/" + locale + ".json")
            .then(({version}) => setVersion(version))
            .catch(err => console.error(err))
    }, [locale])

    return {
        version
    }
}
export {useEulaVersion}