import styles from "./Wrapper.module.css"
import React from "react";
import {ControlBar} from "../../ui/ControlBar";
import {SideBar} from "../../ui/SideBar";
import {useVisibility} from "../../ui/utils/VisibilityProvider";
import {MainMenu} from "./MainMenu";
import {FooterMenu} from "./FooterMenu";
import {useTrackedSelector} from "@digitalstage/api-client";
import {PlaybackOverlay} from "../elements/PlaybackOverlay";
import {DeviceController} from "./DeviceController";
import {NotificationBar} from "../elements/NotificationBar";

const Wrapper = ({children}: { children: React.ReactNode }) => {
    const state = useTrackedSelector()
    const {visible} = useVisibility()

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <NotificationBar/>
                {children}
            </div>
            {state.globals.ready && (
                <>
                    <ControlBar visible={visible}>
                        <DeviceController/>
                    </ControlBar>
                    <PlaybackOverlay/>
                </>
            )}
            {state.globals.ready && (
                <SideBar
                    footer={<FooterMenu/>}
                    visible={visible}
                >
                    <MainMenu/>
                </SideBar>
            )}
        </div>
    )
}
Wrapper.displayName = "Wrapper"

export {Wrapper}