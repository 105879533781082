import {clientActions, logger, useAppDispatch, useEmit, useTrackedSelector} from "@digitalstage/api-client";
import React from "react";
import {ClientDeviceEvents, ClientDevicePayloads, ErrorCodes} from "@digitalstage/api-types";
import {FormattedMessage, useIntl} from "react-intl";
import {useRouter} from "next/router";
import {Container} from "@digitalstage/ui/Container";
import {TextInput} from "@digitalstage/ui/TextInput";

const {trace, error, fatal} = logger("StageJoiner")

export type JoinPayload = ClientDevicePayloads.JoinStage & {
    targetUrl?: string
}

const useStageJoiner = (): {
    join: (request: JoinPayload) => void
    joinByCode: (code: string) => void
    leave: () => void
    resetJoin: () => void
} => {
    const emit = useEmit()
    const dispatch = useAppDispatch()

    const join = React.useCallback(
        (payload: JoinPayload) => {
            trace("Join requested by application, setting join in localStorage and dispatching requestJoin")
            localStorage.setItem("join", JSON.stringify(payload))
            dispatch(clientActions.requestJoin(payload))
        },
        [dispatch]
    )

    const joinByCode = React.useCallback((code: string) => {
        trace("Join by code requested by application, setting code in localStorage and dispatching requestJoin")
        localStorage.setItem("code", code)
        dispatch(clientActions.requestJoin(code))
    }, [dispatch])

    const leave = React.useCallback(() => {
        if (emit)
            emit(ClientDeviceEvents.LeaveStage)
    }, [emit])

    const resetJoin = React.useCallback(() => {
        trace("Resrt join requested by application, removing join from localStorage")
        localStorage.removeItem("join")
    }, [])

    return {
        join,
        joinByCode,
        leave,
        resetJoin,
    }
}

const StageJoiner = ({children}: { children: React.ReactNode }): JSX.Element => {
    const state = useTrackedSelector()
    const {join} = useStageJoiner()
    const dispatch = useAppDispatch()
    const emit = useEmit()
    const [retries, setRetries] = React.useState<number>(0)
    const [errorMessage, setErrorMessage] = React.useState<string>()
    const [intPassword, setIntPassword] = React.useState<string>()
    const intl = useIntl()
    const {push} = useRouter()

    React.useEffect(() => {
        if (emit && state.globals.ready && state.globals.request && typeof state.globals.request === "object") {
            const {targetUrl, ...request} = state.globals.request as JoinPayload
            const payload: ClientDevicePayloads.JoinStage = {
                stageId: request.stageId,
                groupId: request.groupId,
                password: request.password || undefined
            }
            emit(
                ClientDeviceEvents.JoinStage,
                payload,
                (err: string | null) => {
                    if (err) {
                        error(`Error while joining stage: ${err}`)
                        setErrorMessage(err)
                    } else {
                        trace("Successfully joined, removing join from localStorage and dispatching requestJoin with undefined")
                        setErrorMessage(undefined)
                        localStorage.removeItem('join')
                        dispatch(clientActions.requestJoin(undefined))
                        if(targetUrl) {
                            push(targetUrl)
                                .catch(err => console.error(`Could not navigate to ${targetUrl}, reason:`, err))
                        } else {
                            push("/stage")
                                .catch(err => console.error(`Could not navigate to /stage, reason:`, err))
                        }
                    }
                }
            )
        }
    }, [state.globals.ready, state.globals.request, emit, dispatch, push])

    React.useEffect(() => {
        if (emit && state.globals.ready && state.globals.request && typeof state.globals.request === "string") {
            const code = state.globals.request as ClientDevicePayloads.DecodeInviteCode
            emit(
                ClientDeviceEvents.DecodeInviteCode,
                code,
                (err: string | null, result?: { stageId: string; groupId: string; code: string }) => {
                    if (err) {
                        error(`Error while decoding invite code: ${err}`)
                        setErrorMessage(err)
                    } else if (result) {
                        trace("Successfully decoded invite code, dispatching requestJoin")
                        dispatch(clientActions.requestJoin(result))
                    } else {
                        fatal(`Fatal error: no result but also no error when decoding code`)
                    }
                }
            )
        }
    }, [state.globals.ready, state.globals.request, emit, dispatch])

    React.useEffect(() => {
        // Handle storage on external changed (different tab)
        const handleStorage = (event: StorageEvent) => {
            switch (event.key) {
                case "join": {
                    if (event.newValue) {
                        const payload: { stageId: string, groupId?: string, password?: string | null } = JSON.parse(event.newValue)
                        trace("Notified about localStorage join request, dispatching it using requestJoin and removing join from localStorage")
                        dispatch(clientActions.requestJoin(payload))
                        localStorage.removeItem("join")
                    }
                    break;
                }
                case "code": {
                    if (event.newValue) {
                        trace("Notified about localStorage code request, dispatching it using requestJoin and removing code from localStorage")
                        dispatch(clientActions.requestJoin(event.newValue))
                        localStorage.removeItem("code")
                    }
                    break;
                }
            }
        }
        global.window.addEventListener('storage', handleStorage)
        return () => {
            global.window.removeEventListener('storage', handleStorage)
        }
    }, [dispatch])

    const abort = React.useCallback(() => {
        setErrorMessage(undefined)
        setRetries(0)
        dispatch(clientActions.requestJoin(undefined))
    }, [dispatch])

    const validatePassword = React.useCallback(() => {
        if(state.globals.request) {
            if(typeof state.globals.request === "object") {
                setRetries((prev) => prev + 1)
                join({
                    ...state.globals.request as JoinPayload,
                    password: intPassword,
                })
            } else {
                console.error("Cannot apply password when using an invite code")
            }
        }
    }, [join, intPassword, state.globals.request])

    React.useEffect(() => {
        // Handle storage on startup
        const join = localStorage.getItem("join")
        const code = localStorage.getItem("code")
        // Prefer direct joining over code requests
        if (join) {
            trace("Found join request in localStorage, dispatching it using requestJoin and removing all from localStorage")
            const joinRequest = JSON.parse(join) as JoinPayload
            dispatch(clientActions.requestJoin(joinRequest))
        } else if (code) {
            trace("Found code request in localStorage, dispatching it using requestJoin and removing all from localStorage")
            dispatch(clientActions.requestJoin(code))
        }
        localStorage.removeItem("join")
        localStorage.removeItem("code")
    }, [dispatch])

    if (errorMessage) {
        if (retries > 10) {
            return (
                <Container grow>
                    <h1>
                        <FormattedMessage defaultMessage="Too many invalid password attempts" id="Oo0A1z"/>
                    </h1>
                    <p>
                        <FormattedMessage
                            defaultMessage="You've entered a wrong password too many times. Please ask the person you've invited from again and try again later." id="+0YV9a"/>
                    </p>
                    <button onClick={abort}>
                        <FormattedMessage defaultMessage="Ok" id="jwimQJ"/>
                    </button>
                </Container>
            )
        }
        if (errorMessage === ErrorCodes.InvalidInviteCode) {
            return (
                <Container grow>
                    <h1>
                        <FormattedMessage defaultMessage="Invalid invite code" id="JbJ9TI"/>
                    </h1>
                    <p>
                        <FormattedMessage
                            defaultMessage="The invite code you've entered is wrong or no more valid. Please ask the person you've invited from for an new invitation code." id="G9d4aN"/>
                    </p>
                    <button onClick={abort}>
                        <FormattedMessage defaultMessage="Back" id="cyR7Kh"/>
                    </button>
                </Container>
            )
        }
        if (errorMessage === ErrorCodes.StageNotFound) {
            return (
                <Container grow>
                    <h1>
                        <FormattedMessage defaultMessage="Stage not found" id="qJ9Jl8"/>
                    </h1>
                    <p>
                        <FormattedMessage
                            defaultMessage="We could not find your requested stage. Maybe the invitation has been revoked or the link was not copied correctly." id="VDv3ny"/>
                    </p>
                    <button onClick={abort}>
                        <FormattedMessage defaultMessage="Back" id="cyR7Kh"/>
                    </button>
                </Container>
            )
        }
        if (errorMessage === ErrorCodes.InvalidPassword) {
            return (
                <Container grow>
                    <h1>
                        <FormattedMessage defaultMessage="Invalid password" id="eu98dw"/>
                    </h1>
                    <p>
                        <FormattedMessage
                            defaultMessage="This stage required an password. Please ask the person you've invited from for such an password." id="f8jRkJ"/>
                    </p>
                    <TextInput
                        id="password"
                        label={intl.formatMessage({defaultMessage: 'Password', id: '5sg7KC'})}
                        name="password"
                        value={intPassword}
                        onChange={(e) => setIntPassword(e.currentTarget.value)}
                        type="password"
                    />
                    <button onClick={abort}>
                        <FormattedMessage defaultMessage="Abort" id="5FDdF1"/>
                    </button>
                    <button onClick={validatePassword}>
                        <FormattedMessage defaultMessage="Retry" id="62nsdy"/>
                    </button>
                </Container>
            )
        }
        return (
            <Container grow>
                <h1>
                    <FormattedMessage defaultMessage="We got an unhandled error while joining " id="3FY8NF"/>
                </h1>
                <p>
                    <FormattedMessage
                        defaultMessage="We got an unhandled error while joining a stage. Please contact us for further investigation of this error: {error}" id="bW7L4T"
                        values={{error: errorMessage}}/>
                </p>
                <button onClick={abort}>
                    <FormattedMessage defaultMessage="Back" id="cyR7Kh"/>
                </button>
            </Container>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export {StageJoiner, useStageJoiner}
