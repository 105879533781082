import React from "react";
import styles from "./ButtonBar.module.css";

const ButtonBar = ({
                       className,
                       ...props
                   }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => <div
    className={`${styles.bar} ${className || ''}`} {...props}/>

export {ButtonBar}
