import React from "react";
import styles from "./OutsideLayout.module.css";
import Image from "next/image";
import Logo from "../../../public/logo.svg";


const OutsideLayout = ({children, title}: { children: React.ReactNode, title: string }) => {
    return (
        <div className={styles.outerWrapper}>
            <div className={styles.innerWrapper}>
                <header className={styles.header}>
                    <div className={styles.logo}>
                        <Image alt="Digital Stage" src={Logo}/>
                    </div>
                    <h1 className={styles.title}>
                        {title}
                    </h1>
                </header>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}
export {OutsideLayout}
