// Viewport fix for mobile devices
const fixViewport = () => {
    if (typeof window !== 'undefined') {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
}
if (typeof window !== 'undefined') {
    window.addEventListener('resize', fixViewport)
}
export {fixViewport}
