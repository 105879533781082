import {RootState, useEmit, useTrackedSelector} from "@digitalstage/api-client";
import {useIntl} from "react-intl";
import React from "react";
import {ClientDeviceEvents} from "@digitalstage/api-types";
import {MdMic, MdMicOff} from "react-icons/md";

export const isDeviceSendingAudio = (state: RootState, deviceId?: string): boolean => {
    return deviceId && state.devices.byId[deviceId]?.canAudio && state.devices.byId[deviceId]?.sendAudio || false
}

export const getAudioTrackIdsByDevice = (state: RootState, deviceId?: string): string[] => {
    return deviceId &&
        state.globals.stageId
        && state.stageDevices.byStageAndDevice[state.globals.stageId]
        && state.stageDevices.byStageAndDevice[state.globals.stageId][deviceId]
        && state.audioTracks.byStageDevice[state.stageDevices.byStageAndDevice[state.globals.stageId][deviceId]] || []
}

export const areSomeAudioTracksOfDeviceMuted = (state: RootState, deviceId?: string): boolean => {
    return getAudioTrackIdsByDevice(state, deviceId).some(id => state.audioTracks.byId[id]?.muted) || false
}

export const areSomeOfMyTracksNotMuted = (state: RootState): boolean => {
    return state.globals.localUserId && state.audioTracks.byUser[state.globals.localUserId]?.some(id => !state.audioTracks.byId[id]?.muted) || false
}


const WebMicrophoneController = ({deviceId, className}: { deviceId: string, className?: string }) => {
    const state = useTrackedSelector()
    const emit = useEmit()
    const intl = useIntl()

    const sendingAudio = isDeviceSendingAudio(state, deviceId)
    const someMuted = areSomeAudioTracksOfDeviceMuted(state, deviceId)
    const audioTrackIds = getAudioTrackIdsByDevice(state, deviceId)

    const enabled = sendingAudio && !someMuted

    const color = enabled
        ? "success"
        : areSomeOfMyTracksNotMuted(state)
            ? "primary"
            : "danger"

    const handleClick = React.useCallback(() => {
        if (emit) {
            // Enable sending audio on this device
            if (!sendingAudio) {
                emit(ClientDeviceEvents.ChangeDevice, {
                    _id: deviceId,
                    sendAudio: true
                })
            }
            // Un-mute all tracks of this device
            audioTrackIds.map(audioTrackId => {
                emit(ClientDeviceEvents.ChangeAudioTrack, {
                    _id: audioTrackId,
                    muted: enabled
                })
            })
        }
    }, [emit, sendingAudio, audioTrackIds, deviceId, enabled])

    const label = enabled
        ? intl.formatMessage({defaultMessage: 'Share audio from this device', id: 'ncyI2u'})
        : intl.formatMessage({defaultMessage: 'Stop sharing audio from this device', id: 'BACYD9'})

    return (
        <button
            onClick={handleClick}
            className={`large round ${color} ${className || ''}`}
            aria-label={label}
            title={label}
        >
            {enabled ? <MdMic/> : <MdMicOff/>}
        </button>
    )
}

export {WebMicrophoneController}
