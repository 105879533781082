import {useEmit, useTrackedSelector} from "@digitalstage/api-client";
import {useIntl} from "react-intl";
import React from "react";
import {ClientDeviceEvents} from "@digitalstage/api-types";
import {MdMic, MdMicOff} from "react-icons/md";

/**
 * If a deviceId is given, the controller will be enabled if all audio tracks of this device are not muted.
 * If no deviceId is given, the controller will be enabled if all audio tracks of the current user are not muted.
 * @param deviceId
 * @constructor
 */
const JammerMicrophoneController = ({deviceId}: { deviceId?: string }) => {
    const state = useTrackedSelector()
    const emit = useEmit()
    const intl = useIntl()

    // Are all audio tracks of this device muted?
    const stageDeviceId = deviceId && state.globals.stageId && state.stageDevices.byStageAndDevice[state.globals.stageId] && state.stageDevices.byStageAndDevice[state.globals.stageId][deviceId] || undefined
    const audioTrackIds = React.useMemo(() => (stageDeviceId
        ? state.audioTracks.byStageDevice[stageDeviceId]
        : state.globals.localUserId && state.audioTracks.byUser[state.globals.localUserId]) || [], [stageDeviceId, state.audioTracks.byStageDevice, state.audioTracks.byUser, state.globals.localUserId])

    const someMuted = audioTrackIds.some(id => state.audioTracks.byId[id]?.muted)
    const allMuted = audioTrackIds.every(id => state.audioTracks.byId[id]?.muted)

    const handleClick = React.useCallback(() => {
        if (emit) {
            // Only mute/unmute
            audioTrackIds.map(audioTrackId => {
                emit(ClientDeviceEvents.ChangeAudioTrack, {
                    _id: audioTrackId,
                    muted: !allMuted
                })
            })
        }
    }, [allMuted, audioTrackIds, emit])

    const enabled = !allMuted

    const shareMessage = stageDeviceId
        ? intl.formatMessage({defaultMessage: 'Share audio from this device', id: 'ncyI2u'})
        : intl.formatMessage({defaultMessage: 'Share audio', id: 'Y+j0dc'})

    const stopSharingMessage = stageDeviceId
        ? intl.formatMessage({defaultMessage: 'Stop sharing audio from this device', id: 'BACYD9'})
        : intl.formatMessage({defaultMessage: 'Stop sharing audio', id: 'tBe0VE'})

    const label = enabled ? shareMessage : stopSharingMessage

    return (
        <button onClick={handleClick}
                className={`large round ${enabled
                    ? 'success'
                    : someMuted ? 'primary' : 'danger'
                }`}
                aria-label={label}
                title={label}
        >
            {enabled ? <MdMic/> : <MdMicOff/>}
        </button>
    )
}

export {JammerMicrophoneController}
