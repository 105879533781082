import React from "react";
import {useTrackedSelector} from "@digitalstage/api-client";
import {SideBarItem} from "@digitalstage/ui/SideBar";
import {FormattedMessage} from "react-intl";
import {FaBug} from "react-icons/fa";
import {CgLogOff} from "react-icons/cg";
import {IoIosNotifications} from "react-icons/io";

const FooterMenu = (): JSX.Element => {
    const state = useTrackedSelector()
    const hasNotifications = state.notifications.allIds.length > 0

    return (
        <>
            {hasNotifications ? (
                <SideBarItem href="/notifications">
                    <IoIosNotifications/>
                    <span><FormattedMessage defaultMessage="Notifications" id="NAidKb"/></span>
                </SideBarItem>
            ) : null}
            <SideBarItem href={`mailto:support@digital-stage.org?subject=[Bug%20Report%20Website]`} external>
                <FaBug/>
                <span><FormattedMessage defaultMessage="Feedback" id="Ejhdi4"/></span>
            </SideBarItem>
            <SideBarItem href="/account/logout" danger>
                <CgLogOff/>
                <span><FormattedMessage defaultMessage="Sign out" id="xXbJso"/></span>
            </SideBarItem>
        </>
    )
}

export {FooterMenu}