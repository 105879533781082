import {RootState} from "../RootState";
import {BrowserDevice, DeviceType} from "@digitalstage/api-types";

export const selectIsStageAdmin = (state: RootState, stageId: string): boolean => state.globals.localUserId && state.stages.byId[stageId]?.admins?.some((id) => id === state.globals.localUserId) || false

export const selectCurrentStageId = (state: RootState) => state.globals.stageId

export const selectStageType = (state: RootState, stageId: string): DeviceType[keyof DeviceType] => state.stages.byId[stageId].audioType

export const selectIsWebStage = (state: RootState, stageId?: string): boolean => stageId && selectStageType(state, stageId) === "browser" || false

export const selectIsJammerStage = (state: RootState, stageId?: string): boolean => stageId && selectStageType(state, stageId) === "jammer" || false

export const selectIsStageMemberMuted = (state: RootState, stageMemberId: string): boolean => state.audioTracks.byStageMember[stageMemberId] && state.audioTracks.byStageMember[stageMemberId].every(id => state.audioTracks.byId[id].muted) || false

export const selectLocalDevice = (state: RootState): BrowserDevice | undefined => state.globals.ready && state.globals.localDeviceId && state.devices.byId[state.globals.localDeviceId] as BrowserDevice || undefined
