import React from "react";
import {useRouter} from "next/router";
import {
    acceptEula,
    AuthError,
    InternalActionTypes,
    setUser,
    useAppDispatch,
    useTrackedSelector
} from "@digitalstage/api-client";
import {Container} from "@digitalstage/ui/Container";
import styles from "../../gates/EulaGate/EulaGate.module.css";
import {Panel} from "@digitalstage/ui/Panel";
import {FormattedMessage} from "react-intl";
import {Paragraph} from "@digitalstage/ui/Paragraph";
import {NotificationItem} from "@digitalstage/ui/NotificationItem";
import {ButtonBar} from "@digitalstage/ui/ButtonBar";
import Link from "next/link";
import {useEulaVersion} from "./useEulaVersion";

const EulaViewer = (): JSX.Element | null => {
    const state = useTrackedSelector()
    const [error, setError] = React.useState<Error>()
    const {version} = useEulaVersion()
    const {locale} = useRouter()
    const dispatch = useAppDispatch()

    const handleAccept = React.useCallback(() => {
        if (version && state.auth.user && state.globals.authUrl && state.auth.token) {
            setError(undefined)
            const updatedUser = {
                ...state.auth.user,
                eulaVersion: version
            }
            acceptEula(state.globals.authUrl, state.auth.token, version)
                .then(() => dispatch(setUser(updatedUser)))
                .catch((err: AuthError) => setError(err))
        } else {
            setError(new Error("Not ready"))
        }
    }, [version, state.auth.token, state.globals.authUrl])

    if (locale) {
        return (
            <Container grow className={styles.container}>
                <Panel className={styles.panel} data-cy="eula">
                    <h2 className="m-0">
                        <FormattedMessage defaultMessage="End User License Agreement" id="IucxcM"/>
                    </h2>
                    <Paragraph micro>
                        <FormattedMessage
                            defaultMessage="Please first accept our current end user license agreement to continue using Digital Stage"
                            id="cwqsQR"/>
                    </Paragraph>
                    <iframe className={styles.iframe} src={`/eula/${locale}.html`}/>
                    {error &&
                        <NotificationItem kind="error">{error.message || 'Error while accepting'}</NotificationItem>}
                    <ButtonBar>
                        <Link href={`/account/logout`}>
                            <button className={`danger`}>
                                <FormattedMessage defaultMessage="Disagree and sign out" id="FA6qd7"/>
                            </button>
                        </Link>
                        <button data-cy="accept" className={`success ${styles.button}`} onClick={handleAccept}>
                            <FormattedMessage defaultMessage="I agree" id="5h3TFE"/>
                        </button>
                    </ButtonBar>
                </Panel>
            </Container>
        )
    }
    return null
}
export {EulaViewer}
