import '../styles/globals.css'
import type {AppProps} from 'next/app'
import {Wrapper} from "../components/Wrapper";
import {DigitalStageProvider, setEnvironment, useTrackedSelector} from "@digitalstage/api-client";
import dynamic from 'next/dynamic'
import {useRouter} from "next/router";
import {useAppDispatch} from "../client/redux/hooks";
import React from "react";
import German from "content/compiled-locales/de.json";
import English from "content/compiled-locales/en.json";
import {IntlProvider} from "react-intl";
import {ConnectionGate} from "../components/gates/ConnectionGate";
import {StageJoiner} from "../components/elements/StageJoiner";
import {VisibilityProvider} from "@digitalstage/ui/utils/VisibilityProvider";
import {BrowserGate} from "../components/gates/BrowserGate";
import {fixViewport} from "@digitalstage/ui/utils/fixViewport";
import {EulaGate} from "../components/gates/EulaGate";

// Load services only on browser devices (NO SSR)
const DigitalStageService = dynamic(() => import('../client/react/DigitalStageService'), {ssr: false})

// Fix viewport on mobile devices
fixViewport()

const EnvironmentalFetcher = React.memo((): null => {
    const {isReady, query, replace} = useRouter();
    const runOnce = React.useRef<boolean>(false)
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (!runOnce.current && isReady) {
            runOnce.current = true
            //TODO: Fix me: this is called on every navigation
            const token = query.authtoken && Array.isArray(query.authtoken)
                ? query.authtoken[0]
                : query.authtoken || undefined
            if (token) {
                // Hide token from url
                replace("/", "/", {shallow: true})
                    .catch(err => console.error(err))
            }
            fetch("/api/config")
                .then(result => result.json())
                .then((data: { apiUrl: string, authUrl: string, jnUrl: string, logUrl?: string, envName?: string }) => {
                    console.info("Fetched environmental description")
                    dispatch(setEnvironment({...data, token}))
                })
                .catch(err => console.error(err))
        }
    }, [dispatch, isReady, query, replace]);

    return null
})
EnvironmentalFetcher.displayName = "EnvironmentalFetcher"

const BodyBackground = React.memo((): null => {
    const state = useTrackedSelector()

    React.useEffect(() => {
        if (state.globals.stageId) {
            document.body.classList.toggle('inside', true)
        } else {
            document.body.classList.toggle('inside', false)
        }
    }, [state.globals.stageId])

    return null
})
BodyBackground.displayName = "BodyBackground"

function MyApp({Component, pageProps}: AppProps) {
    const {locale} = useRouter();
    const [shortLocale] = locale ? locale.split("-") : ["en"];
    const messages = React.useMemo(() => {
        switch (shortLocale) {
            case "de":
                return German;
            case "en":
                return English;
            default:
                return German;
        }
    }, [shortLocale]);


    return (
        <IntlProvider
            locale={shortLocale}
            messages={messages}
            onError={() => null}>
            <BrowserGate>
                <DigitalStageProvider>
                    <EnvironmentalFetcher/>
                    <BodyBackground/>
                    <EulaGate>
                        <ConnectionGate>
                            <VisibilityProvider>
                                <Wrapper>
                                    <StageJoiner>
                                        <Component {...pageProps} />
                                    </StageJoiner>
                                </Wrapper>
                            </VisibilityProvider>
                        </ConnectionGate>
                        <DigitalStageService/>
                    </EulaGate>
                </DigitalStageProvider>
            </BrowserGate>
        </IntlProvider>
    )
}

export default MyApp
