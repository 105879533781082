import React from "react";
import {useTrackedSelector} from "@digitalstage/api-client";
import {useRouter} from "next/router";
import {EulaViewer} from "../../elements/EulaViewer";


const EulaGate = ({children}: { children: React.ReactNode }): JSX.Element => {
    const state = useTrackedSelector()
    const {pathname, locale} = useRouter()
    const [version, setVersion] = React.useState<number>()

    // Get eula version
    React.useEffect(() => {
        import ("../../../content/eula/" + locale + ".json")
            .then(({version}) => setVersion(version))
            .catch(err => console.error(err))
    }, [locale])

    if (version && state.auth.user && pathname !== '/account/logout') {
        if (!state.auth.user.eulaVersion || state.auth.user.eulaVersion < version) {
            return <EulaViewer/>
        }
    }
    return (
        <>
            {children}
        </>
    )
}

export {EulaGate}