import styles from "./Paragraph.module.css"
import React from "react";

const Paragraph = ({center, children, micro, className}: {
    className?: string,
    children: React.ReactNode,
    micro?: boolean
    center?: boolean
}) => <p className={`${styles.p} ${micro ? styles.micro : ''} ${center ? styles.center : ''} ${className || ''}`}>{children}</p>

export {Paragraph}
