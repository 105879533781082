import React from "react";
import {useTrackedSelector} from "@digitalstage/api-client";
import {SideBarItem} from "@digitalstage/ui/SideBar";
import {FormattedMessage} from "react-intl";
import {BiChat, BiCube, BiDevices, BiGridAlt} from "react-icons/bi";
import {GoListUnordered, GoSettings} from "react-icons/go";
import {HiOutlineUserGroup} from "react-icons/hi";
import {FaBug, FaTools} from "react-icons/fa";

const MainMenu = (): JSX.Element => {
    const state = useTrackedSelector()
    const insideStage = !!state.globals.stageId
    const isStageAdmin = state.globals.localUserId && state.globals.stageId && state.stages.byId[state.globals.stageId]?.admins.includes(state.globals.localUserId) || false
    const isJammerStage = state.globals.stageId ? state.stages.byId[state.globals.stageId]?.audioType === "jammer" : false
    const show3DEditor = !isJammerStage && state.globals.stageId ? state.stages.byId[state.globals.stageId]?.render3DAudio : false
    const deviceCount = state.devices.allIds.filter(id => state.devices.byId[id].type !== "jammer").length
    const advancedEnabled = state.globals.localDeviceId && state.devices.byId[state.globals.localDeviceId]?.showAdvanced || false

    return (
        <>
            {insideStage ? (
                <>
                    <SideBarItem href="/stage">
                        <BiGridAlt/>
                        <span><FormattedMessage defaultMessage="Stage" id="uYAYib"/></span>
                    </SideBarItem>
                    <SideBarItem href="/mixer">
                        <GoSettings/>
                        <span><FormattedMessage defaultMessage="Stage mixer" id="greV2R"/></span>
                    </SideBarItem>
                    {show3DEditor ? (
                        <SideBarItem href="/room">
                            <BiCube/>
                            <span><FormattedMessage defaultMessage="Room editor" id="GCrXms"/></span>
                        </SideBarItem>
                    ) : null}
                    {isStageAdmin ? (
                        <SideBarItem href="/stage/manage">
                            <HiOutlineUserGroup/>
                            <span><FormattedMessage defaultMessage="Manage" id="0Azlrb"/></span>
                        </SideBarItem>
                    ) : null}
                    <SideBarItem href="/chat">
                        <BiChat/>
                        <span><FormattedMessage defaultMessage="Chat" id="WTrOy3"/></span>
                    </SideBarItem>
                </>
            ) : null}
            {deviceCount > 1 ? (
                <SideBarItem href="/devices">
                    <BiDevices/>
                    <span><FormattedMessage defaultMessage="My devices" id="OgeT1t"/></span>
                </SideBarItem>
            ) : undefined}
            <SideBarItem href="/settings/device">
                <FaTools/>
                <span><FormattedMessage defaultMessage="Settings" id="D3idYv"/></span>
            </SideBarItem>
            {advancedEnabled && (
                <SideBarItem href="/stats">
                    <FaBug/>
                    <span><FormattedMessage defaultMessage="Statistics" id="H3eDAS"/></span>
                </SideBarItem>
            )}
            <SideBarItem href="/stages">
                <GoListUnordered/>
                <span><FormattedMessage defaultMessage="My stages" id="LccwG2"/></span>
            </SideBarItem>
        </>
    )
}

export {MainMenu}