import styles from "./Container.module.css"
import React from "react";

const Container = ({children, center, grow, narrow, full, className}: { children: React.ReactNode, center?: boolean, narrow?: boolean, grow?: boolean, full?: boolean, className?: string }) => {
    return (
        <div className={`${styles.container} ${grow ? styles.grow : ''} ${narrow ? styles.narrow : ''} ${full ? styles.full : ''}`}>
            <main className={`${styles.main} ${center ? styles.center : ''} ${className || ''}`}>
                {children}
            </main>
        </div>
    )
}
export {Container}