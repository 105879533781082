import styles from "./ControlBar.module.css"
import React from "react";

const ControlBar = ({visible, children}: { visible?: boolean, children: React.ReactNode }) => {
  return (
      <div className={`${styles.bar} ${visible ? styles.visible : ''}`}>
          {children}
      </div>
  )
}
ControlBar.displayName = "ControlBar"
export {ControlBar}