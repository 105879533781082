import React from "react";
import * as Bowser from "bowser";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./BrowserGate.module.css"
import {DownloadLink} from "@digitalstage/ui/DownloadLink";
import {OutsideLayout} from "../../layouts/OutsideLayout";
import {detectDevice} from "mediasoup-client";

const BrowserGate = ({children}: { children: React.ReactNode }) => {
    const [invalid, setInvalid] = React.useState<"unsupported" | "update-ios" | "update-macos" | undefined>(undefined)
    const intl = useIntl()

    // Late checking, but essential when using SSR like next.js
    React.useEffect(() => {
        if (global.window) {
            // Using mediasoup detector, since we are using it mainly now (WebRTC direct connections are now optional):
            const browser = detectDevice()
            if(browser) {
                console.log(browser)
            } else {
                const parser = Bowser.getParser(global.window.navigator.userAgent)
                // Warn user to update macOS or iOS if using unsupported safari
                if(parser.getBrowser().name === "Safari") {
                    if (parser.getOS().name === "iOS") {
                        setInvalid('update-ios')
                    } else {
                        setInvalid('update-macos')
                    }
                } else {
                    setInvalid('unsupported')
                }
            }
        }
    }, [])

    if (invalid) {
        if (invalid === "update-ios") {
            return (
                <OutsideLayout title={intl.formatMessage({defaultMessage: 'Your iOS version is unsupported ', id: 'g/UAkm'})}>
                    <p className="featured">
                        <FormattedMessage
                            defaultMessage="Please update your Apple device and visit Digital Stage again." id="rMtM8q"/>
                    </p>
                </OutsideLayout>
            )
        } else if (invalid === "update-macos") {
            return (
                <OutsideLayout title={intl.formatMessage({defaultMessage: 'Your macOS version is unsupported ', id: 'h0vgqZ'})}>
                    <p className="featured">
                        <FormattedMessage
                            defaultMessage="Please consider updating macOS to the latest version or use of one those supported browser:" id="96/JJz"/>
                    </p>
                    <div className={styles.downloadLinks}>
                        <DownloadLink href="https://www.mozilla.org/">
                            <FormattedMessage defaultMessage="Download Mozilla Firefox" id="HI8DT4"/>
                        </DownloadLink>

                        <DownloadLink href="https://www.google.com/chrome/">
                            <FormattedMessage defaultMessage="Download Google Chrome" id="nTkC2P"/>
                        </DownloadLink>
                    </div>
                </OutsideLayout>
            )
        }
        return (
            <OutsideLayout title={intl.formatMessage({defaultMessage: 'Sorry, but your browser is not supported', id: 'Xfptrx'})}>
                <p className="featured">
                    <FormattedMessage
                        defaultMessage="Please try again using the latest version of one of those browsers:" id="uQmoIg"/>
                </p>
                <div className={styles.downloadLinks}>
                    <DownloadLink href="https://www.mozilla.org/">
                        <FormattedMessage defaultMessage="Download Mozilla Firefox" id="HI8DT4"/>
                    </DownloadLink>

                    <DownloadLink href="https://www.google.com/chrome/">
                        <FormattedMessage defaultMessage="Download Google Chrome" id="nTkC2P"/>
                    </DownloadLink>
                </div>
            </OutsideLayout>
        )
    }

    return (
        <>
            {children}
        </>
    )
}
export {BrowserGate}
